import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import Contact from '@models/Contact';
import CustomEmail from '@models/CustomEmail';
import Customer from '@models/Customer';
import Sector from '@models/Sector';
import HttpResponse from '@services/apiResponse/HttpResponse';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import * as React from 'react';

export interface ISendPageProps {
  contacts?: Array<Contact>
  customer?: Customer
  dateRdv?: Date
  type: string
  to?: string
}

export default class SendToMultiplePage extends React.Component<ISendPageProps, {submitting: boolean, to: string, edit: boolean, subject: string, text: string, credit, customer: Customer, email: CustomEmail}> {

  public hasTyped = false;

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      edit: false,
      subject: "",
      text: "",
      credit: null,
      to: this.props.contacts.map((contact) => {return contact.email} ).toString(),
      customer: null,
      email: null
    }
  }


  onPickEmail(email: CustomEmail) {
    this.setData(email)
    this.setState({
      edit: true,
      subject: email.getRealObjectWith(),
      text: email.getRealContentWith(),
      email: email
    })
  }

  async loadCredit() {
    let res = await EntityManager.get(CustomEmail, {path: "plan"});
    this.setState({credit: res.data});
  }

  async loadCustomer(){
    const sector = (await EntityManager.get<Sector>(Sector, {path: this.props.contacts[0].sectorId})).model;
    this.setState({"customer": sector.customer})
  }

  componentDidMount(): void {
    this.loadCredit();
    this.loadCustomer();
  }

  setData(email: CustomEmail) {
    const {contacts, customer, dateRdv} = this.props
    email.withContact(contacts[0]).withCustomer(customer).withDateRdv(dateRdv);
  }

  async send() {
    const {email} = this.state
    const {contacts} = this.props
    this.setState({submitting: true});
    let successMessage: string
    let count = 0;
    successMessage = "L'email a bien été envoyé "
    
    const promises = contacts.map(async (contact) => {
        if (contact.email) {
            email.withContact(contact);
            let subject = email.getRealObjectWith();
            let text = email.getRealContentWith();
            let params = {
                subject,
                text,
                to: contact.email,
                contactId: contact?.id
            };

            const response = await EntityManager.postDirect(Contact, { path: "email", params });
            
            if (response.ok) {
                count++;
            }
        }
    });

    // Attendre que toutes les promesses soient résolues
    await Promise.all(promises);
      
    DOMService.closeSidebar();
    setTimeout(() => DOMService.setToaster(successMessage + " a " + count + " contacts."), 500);

  }

  renderItem(template) {
    return <div  onClick={() => this.onPickEmail(template)} className='mb-3 border rounded-2 pointer py-3 px-4'>
      <div className="row-flex">
        <i className='fas fa-envelope me-4 fa-2x text-secondary'></i>
        <div>
          <div>
            {template.title}
          </div>
          <div className='text-secondary'>
            {template.object}
          </div>
          {(template.needRdvDate() && !this.props.dateRdv) && <div className='text-danger text-s'>
            Il est conseillé de sélectionner un rendez-vous afin d'utiliser ce template.
          </div>}
          {/* {template.needContact() && <div className='text-danger text-s'>
            Il est conseillé de sélectionner un contact afin d'utiliser ce template.
          </div>} */}
        </div>
      </div>
    </div>
  }

  public render() {
    const {submitting, subject, text, to, edit, credit} = this.state
    const {type} = this.props
    return (
      <SidebarLayout
        title={"Envoyer un "+ (type)} 
        action={<div>{(credit ? " (" + credit + " crédits restant)" : "")}</div>}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={() => this.send()} >Envoyer</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartInputText value={to} onChange={(to) => this.setState({to})} label="Destinataire" containerClassName='mb-3' />
          {edit && <div className="mb-4">
            <div>
              {type === "email" && <SmartInputText value={subject} onChange={(subject) => this.setState({subject})} label="Objet" containerClassName='mb-3' />}
              <SmartInputTextarea value={text} onChange={(text) => this.setState({text})} label="Corps" containerClassName='mb-3' rows={12} />
            </div>
          </div>}
          <div className='mb-3 fw-bold'>
            Vos modèles
          </div>
          {this.renderItem(new CustomEmail({type, title: "Saisie libre"}))}
          <RecordManagerV2<CustomEmail> options={{modelClass: CustomEmail, loadOnReady: true, paginate: false, params: {type}}}>
            {(manager, props) => <div className="">
              {props.models?.map((m) => {
                return this.renderItem(m);
              })}
            </div>}
          </RecordManagerV2>
        </div>
      </SidebarLayout>
    );
  }
}
